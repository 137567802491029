import React from "react";
import Layout from "../components/layout";
import InfoBox from "../components/InfoBox";
import PageHeader from "../components/PageHeader/PageHeader";
import { PageContainer, PageContent, Section } from "../styles";
import Helmet from "react-helmet";

import Footer from "../components/Footer";

export default function Services() {
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Services - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Services" description="The approach to your treatment" />
      </PageContainer>
      <div className="container">
        <div className="row">
          <PageContent>
            <Section>
              <p>
                It’s through guided conversation. We all have times of challenge and being
                stuck. It will come apparent, the areas in which i’m able to help, you
                will see that your ways of thinking or past experience has led you to this
                place in time, where the challenges of life are causing you distress.
                Everything is subject to change, and together we can find a solution .
              </p>
              <p>
                CBT is an evidence-based therapy, which has proven to work with many
                issues such as:
              </p>
              <InfoBox>
                <ul>
                  <li>Anxiety</li>
                  <li>Fear (OCD) </li>
                  <li>Depression</li>
                  <li>Low Self Esteem</li>
                  <li>Trauma</li>
                  <li>Stress</li>
                  <li>Work</li>
                  <li>Relationships</li>
                  <li>Past Experiences</li>
                  <li>Sleep Difficulties</li>
                </ul>
              </InfoBox>
              <p>
                This is not an exhaustive list. The strategies involved in CBT helps
                promote wellness and are solution-focused.
              </p>
            </Section>
          </PageContent>
        </div>
      </div>
      <div style={{ background: "rgb(30, 36, 54)" }}>
        <Footer contactForm={true} />
      </div>
    </Layout>
  );
}
